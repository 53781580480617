<template>
  <div>
    <!-- Slider main container -->
    <div class="swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <img
            src="@/assets/img/底妆素材/kv.jpg"
            alt=""
          >
        </div>
      </div>
      <div class="bg">
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>
    </div>
    <!-- <div class="goods">
      <div
        ref="box"
        class="item"
        v-for="(item,index) in imgs"
        :key="index"
      >
        <div
          :class="isActive===index?'active bg-box':'bg-box'"
          :style="{height:heightNum+'px'}"
          @mouseenter="handleEnter(index)"
          @mouseleave="handleLeave"
          @click="handleRoute(index)"
        >
          <div
            class="bg default"
            :style="{background:'url('+item.defalut+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div
            class="bg active"
            :style="{background:'url('+item.active+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div class="name">{{item.name}}</div>
        </div>
        <div class="box-b">
          <div class="desc">
            <div class="cname">{{item.cname}}</div>
            <div class="info">{{item.info}}</div>
          </div>
          <div
            class="btn"
            @click="handleRoute(index)"
          >Learn More</div>
        </div>
      </div>
    </div> -->
    <div class="goods1">
      <div
        ref="box"
        class="item item1"
        v-for="(item,index) in imgs"
        :key="index"
      >
        <div
          :class="isActive1===index?'active bg-box':'bg-box'"
          :style="{height:heightNum+'px'}"
          @mouseenter="handleEnter1(index)"
          @mouseleave="handleLeave1"
          @click="handleRoute1(index)"
        >
          <div
            class="bg default"
            :style="{background:'url('+item.defalut+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div
            class="bg active"
            :style="{background:'url('+item.active+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div class="name">{{item.name}}</div>
        </div>
        <div class="box-b">
          <div class="desc">
            <div class="cname">{{item.cname}}</div>
            <div class="info">{{item.info}}</div>
          </div>
          <div
            class="btn"
            @click="handleRoute(index)"
          >Learn More</div>
        </div>
      </div>
    </div>
    <div class="goods1">
      <div
        ref="box"
        class="item item2"
        v-for="(item,index) in imgs1"
        :key="index"
      >
        <div
          :class="isActive2===index?'active bg-box':'bg-box'"
          :style="{height:heightNum1+'px'}"
          @mouseenter="handleEnter2(index)"
          @mouseleave="handleLeave2"
          @click="handleRoute2(index)"
        >
          <div
            class="bg default"
            :style="{background:'url('+item.defalut+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div
            class="bg active"
            :style="{background:'url('+item.active+') no-repeat center',backgroundSize:'cover'}"
          ></div>
          <div class="name">{{item.name}}</div>
        </div>
        <div class="box-b">
          <div class="desc">
            <div class="cname">{{item.cname}}</div>
            <div class="info">{{item.info}}</div>
          </div>
          <div
            class="btn"
            @click="handleRoute2(index)"
          >Learn More</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img
        class="bg"
        src="@/assets/img/底妆素材/bot.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

export default {
  data() {
    return {
      isActive1: '',
      isActive2: '',
      widthNum: 0,
      imgs: [
        {
          defalut: require('@/assets/img/底妆素材/产品模特图/BKFB1.jpg'),
          active: require('@/assets/img/底妆素材/产品模特图/BKFB2.jpg'),
          name: 'Spiral Shell Amplifier Powder',
          cname: '酵色「贝壳粉饼」',
          info: 'Q弹帖肤不卡粉 清透柔焦伪素颜'
        },
        {
          defalut: require('@/assets/img/底妆素材/产品模特图/ZX1.jpg'),
          active: require('@/assets/img/底妆素材/产品模特图/ZX2.jpg'),
          name: 'Winter Sweet Tip Concelear',
          cname: '酵色「小奶条遮瑕」',
          info: '痘肌可用 温和遮瑕无负担'
        }
      ],
      imgs1: [
        {
          defalut: require('@/assets/img/底妆素材/产品模特图/FS1.jpg'),
          active: require('@/assets/img/底妆素材/产品模特图/FS2.jpg'),
          name: 'Winter Sweet Cream Foundation',
          cname: '酵色「保湿小奶霜」',
          info: '细腻贴肤低粉感'
        },
        {
          defalut: require('@/assets/img/底妆素材/产品模特图/FCFD1.jpg'),
          active: require('@/assets/img/底妆素材/产品模特图/FCFD2.jpg'),
          name: 'Milk Tea Foundation',
          cname: '酵色「奶茶粉底液」',
          info: '清透奶油肌 细腻控油无妆感'
        },
        {
          defalut: require('@/assets/img/底妆素材/产品模特图/XRP1.jpg'),
          active: require('@/assets/img/底妆素材/产品模特图/XRP2.jpg'),
          name: 'Highlight&Contouring Palette',
          cname: '酵色「三色高光修容盘」',
          info: '一盘雕塑立体轮廓'
        }
      ],
    }
  },
  computed: {
    heightNum() {
      return this.widthNum
    },
    heightNum1() {
      return this.widthNum * 0.83

    }
  },
  methods: {
    handleEnter1(i) {
      this.isActive1 = i
    },
    handleLeave1(i) {
      this.isActive1 = ''
    },
    handleEnter2(i) {
      this.isActive2 = i
    },
    handleLeave2(i) {
      this.isActive2 = ''
    },
    handleRoute1(id) {
      const name = '底妆'
      this.$router.push({ name: 'detail', query: { id, name } })
    },
    handleRoute2(id) {
      const name = '贝壳系列2'
      // this.$router.push({ name: 'detail', query: { id, name } })
    }
  },
  mounted() {
    this.widthNum = this.$refs.box[0].clientWidth
    window.onresize = () => {
      this.widthNum = this.$refs.box[0].clientWidth
    }
    const swiper = new Swiper('.swiper-container', {
      //   autoplay: {
      //     delay: 3000,
      //     disableOnInteraction: false
      //   },
      speed: 2000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'my-bullet',
        bulletActiveClass: 'my-bullet-active',
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 388px;
    height: 209px;
    transform: translate(-50%, -50%);
    background: url("../../../assets/img/底妆素材/text.png") no-repeat center;
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.goods1 {
  display: flex;
  justify-content: space-between;

  margin: 61px 55px 66px;
  .item1 {
    width: 828px;
  }
  .item2 {
    width: 523px;
  }
  .item {
    // width: 728px;
    .bg-box {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .bg.default {
        opacity: 1;
        transition: 0.4s;
      }
      .bg.active {
        transform: scale(1.1);
        opacity: 0;
        transition: 0.6s ease-out;
      }
      .name {
        position: absolute;
        top: 9px;
        left: 7px;
        color: #ffffff;
      }
    }
    .bg-box.active {
      .bg.default {
        opacity: 0;
      }
      .bg.active {
        transform: scale(1);
        opacity: 1;
      }
    }
    .box-b {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;
      font-size: 12px;
      .btn {
        width: 89px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 13px;
        border: 1px solid #0a0406;
        cursor: pointer;
      }
    }
  }
}
.bottom {
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
  }
}
</style>